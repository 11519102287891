import React, { useState, useContext, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { Paper } from '@mui/material';

import Order from '../components/order';
import { AppContext } from '../context/AppContext';
import GET_ORDER from '../queries/get-order';
import UPDATE_ORDER from '../mutations/update-order';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { v4 } from 'uuid';
import { useParams } from 'react-router-dom';

const styles = {
  root: {
    width: '100%',
  },
  notice: {
    backgroundColor: (theme) => theme.palette.background.paper,
    padding: (theme) => theme.spacing(2),
  },
  button: {
    marginTop: (theme) => theme.spacing(1),
    marginRight: (theme) => theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: (theme) => theme.spacing(2),
    marginTop: (theme) => theme.spacing(3),
  },
  resetContainer: {
    padding: (theme) => theme.spacing(3),
  },
  paper: {
    padding: (theme) => theme.spacing(6),
  },
  circularProgress: {
    verticalAlign: 'bottom',
    color: (theme) => theme.palette.secondary.main,
    width: '30px',
    height: '30px',
  },
}

const OrderPage = () => {
  const { order, setOrder, setRequestError, nullOrder, setOpenBackdrop, disableTelesales, setDisableTelesales } = useContext(AppContext);
  const orderParams = useParams();
  const orderId = Number.parseInt( orderParams.orderId )
  const [ dialogOpen, setDialogOpen ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);

  const [ applyCoupon, { loading: loadingCoupon } ] = useMutation(UPDATE_ORDER);

  const [ getOrder ] = useLazyQuery(GET_ORDER)

  useEffect(() => {
    if( 0 === orderId ) {
      setOrder( nullOrder )
      setIsLoading( false )
      setOpenBackdrop( false )
      return;
    }

    if( orderId !== order.databaseId ) {
      getOrder({
        variables: {
          ids: [ orderId ]
        },
        onCompleted: (response => {
            setOrder( response.orders.nodes[ 0 ] )
            setIsLoading( ! order.databaseId )
            setOpenBackdrop( false )
        } ),
        onError: (error) => {
          setRequestError( error )
          setIsLoading( false )
          setOpenBackdrop( false )
        }
      } )
    }

    setIsLoading( false )
  }, [ orderParams ])

  useEffect(() => {
    setOpenBackdrop(isLoading)
  }, [ isLoading ])

  const handleDiscountDialog = (event) => {
    event.preventDefault();
    setDialogOpen( !dialogOpen )
  }
  
  const setDialogClose = () => {
    setDialogOpen( false )
  }

  const handleDiscount = (event) => {
    event.preventDefault()
    setDisableTelesales(true)

    const discountCodesElement = document.querySelector(`#discount-${ order.databaseId }`)
    const newCodes = discountCodesElement.value.trim().split(',').filter( String );
    const orderCodes = order.couponLines?.nodes.map(couponLine => couponLine.coupon.code);
    const noDupesCodes = newCodes.filter(code => !orderCodes.includes(code));
    
    if (0 === noDupesCodes.length) {
      setDialogClose()
      setRequestError('Code already applied')
      setDisableTelesales(false)
      return
    }

    const unfilteredCodes = noDupesCodes.concat(orderCodes);
    const codes = unfilteredCodes.filter(code => undefined !== code );
    
    applyCoupon({
      variables: {
        input:{
          clientMutationId: v4(),
          orderId: Number.parseInt(order.databaseId),
          isPaid: false,
          coupons: codes,
        },
      },
    })
    .then((result) => {
      setOrder( result.data.updateOrder.order )
    })
    .catch((err) => {
      setRequestError(err.message)
    })
    .finally(() => {
      setDialogClose()
      setDisableTelesales(false)
    })
  }

  return (
      <Paper sx={ { ...styles.paper } }>
          <Box component="div" sx={ { ...styles.root } }>
              <Grid container spacing={ 4 }>
                  <Grid item xs={ 12 }>
                      {
                        isLoading ? 
                            <div /> 
                          :
                            <Order
                              order={ order }
                              dialogOpen={ dialogOpen }
                              addresses={ { billing: order.billing, shipping: order.shipping } }
                              resetFunc={ setDialogClose }
                              discountFunc={ handleDiscount }
                              dialogFunc={ handleDiscountDialog }
                              closeDialog={ setDialogClose }
                              loadingCoupon={ loadingCoupon }
                              disableTelesales={ disableTelesales }
                              setDisableTelesales={ setDisableTelesales }
                            />
                      }
                  </Grid>
              </Grid>
          </Box>
      </Paper>
  )
}

export default OrderPage;